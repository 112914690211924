import React from "react"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Navbar from "../components/navbar"
import CatalogFullscreenSections from "../components/catalog-fullscreen-sections"
import CookiesAlert from "../components/cookies-info-alert"
import SEO from "../components/seo"
import { Link, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import "./catalog.scss"
import FloatingContacts from "../components/floating-contact-icons"
import Fade from 'react-reveal/Fade'

const CatalogPage = (props) => {

  return (
    <>
      <SEO
        title="Каталог"
        keywords={["Шкафы", "Шкафы-купе", "Кухни", "Прихожие", "Гардеробные", "Тумбы", "Комоды", "Тумбы и комоды", "Мебель", "Материалы", "Декор", "Техника для кухни"]}
        description="Каталог на сайте Империя студия мебели - шкафы, кухни, прихожие, гардеробные, тумбы, комоды и иная мебель, материалы, декор и варианты техники."
      />
      <Navbar />
      <Container fluid className="my-2 pt-2 categories-container">
        <Row className="mt-5">
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Fade duration={1000} delay={150}>
              <Card className="bg-light text-white rounded-0 border-0 m-3 hoverable" onClick={() => navigate(`/kitchens`)}>
                <Img
                  fluid={props.data.kitchen.childImageSharp.fluid}
                />
                <Card.ImgOverlay className="rgba-black-strong-imperia" style={{ bottom: '60px', }}>

                  <Card.Text className="h5 font-weight-normal">Кухни, столешницы, встраиваемая техника</Card.Text>
                  <Card.Text className="h5 font-weight-normal">
                    Производство на заказ по индивидуальным проектам
                </Card.Text>
                  <div className="btn-container mt-3"><Link
                    to="/kitchens"
                    className="btn btn-outline-light font-weight-light"
                  >
                    Смотреть
          </Link></div>

                </Card.ImgOverlay>
                <Card.Footer className="rgba-black-strong-imperia" style={{ height: '60px', }}><h2 className="m-0 text-center text-white font-weight-normal">Кухни</h2></Card.Footer>
              </Card>
            </Fade>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Fade duration={1000} delay={150}>
              <Card className="bg-light text-white rounded-0 border-0 m-3 hoverable" onClick={() => navigate(`/cupboards`)}>
                <Img
                  fluid={props.data.cupboard.childImageSharp.fluid}
                />
                <Card.ImgOverlay className="rgba-black-strong-imperia" style={{ bottom: '60px', }}>
                  <Card.Text className="h5 font-weight-normal">Производство шкафов, шкафов-купе</Card.Text>
                  <Card.Text className="h5 font-weight-normal">По индивидуальным размерам</Card.Text>
                  <div className="btn-container mt-3">
                    <Link
                      to="/cupboards"
                      className="btn btn-outline-light font-weight-light"
                    >
                      Смотреть
          </Link>
                  </div>
                </Card.ImgOverlay>
                <Card.Footer className="rgba-black-strong-imperia" style={{ height: '60px', }}><h2 className="m-0 text-center text-white font-weight-normal">Шкафы</h2></Card.Footer>
              </Card>
            </Fade>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Fade duration={1000} delay={150}>
              <Card className="bg-light text-white rounded-0 border-0 m-3 hoverable" onClick={() => navigate(`/hallways`)}>
                <Img
                  fluid={props.data.hallway.childImageSharp.fluid}
                />
                <Card.ImgOverlay className="rgba-black-strong-imperia" style={{ bottom: '60px', }}>

                  <Card.Text className="h5 font-weight-normal">
                    Мебель для прихожих по индивидуальным проектам
                </Card.Text>
                  <Card.Text className="h5 font-weight-normal">На заказ</Card.Text>
                  <div className="btn-container mt-3">
                    <Link
                      to="/hallways"
                      className="btn btn-outline-light font-weight-light"
                    >
                      Смотреть
          </Link>
                  </div>
                </Card.ImgOverlay>
                <Card.Footer className="rgba-black-strong-imperia" style={{ height: '60px', }}><h2 className="m-0 text-center text-white font-weight-normal">Прихожие</h2></Card.Footer>
              </Card>
            </Fade>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Fade duration={1000} delay={150}>
              <Card className="bg-light text-white rounded-0 border-0 m-3 hoverable" onClick={() => navigate(`/stands`)}>
                <Img
                  fluid={props.data.stand.childImageSharp.fluid}
                />
                <Card.ImgOverlay className="rgba-black-strong-imperia" style={{ bottom: '60px', }}>

                  <Card.Text className="h5 font-weight-normal">Тумбы под ТВ, комоды и т.д.</Card.Text>
                  <Card.Text className="h5 font-weight-normal">Дизайн на заказ</Card.Text>
                  <div className="btn-container mt-3"><Link
                    to="/stands"
                    className="btn btn-outline-light font-weight-light"
                  >
                    Смотреть
          </Link></div>

                </Card.ImgOverlay>
                <Card.Footer className="rgba-black-strong-imperia" style={{ height: '60px', }}><h2 className="m-0 text-center text-white font-weight-normal">Тумбы и комоды</h2></Card.Footer>
              </Card>
            </Fade>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Fade duration={1000} delay={150}>
              <Card className="bg-light text-white rounded-0 border-0 m-3 hoverable" onClick={() => navigate(`/dressing-rooms`)}>
                <Img
                  fluid={props.data.dressingRoom.childImageSharp.fluid}
                />
                <Card.ImgOverlay className="rgba-black-strong-imperia" style={{ bottom: '60px', }}>

                  <Card.Text className="h5 font-weight-normal">Гардеробные комнаты, стенки для хранения</Card.Text>
                  <Card.Text className="h5 font-weight-normal">По индивидуальным проектам</Card.Text>
                  <div className="btn-container mt-3"><Link
                    to="/dressing-rooms"
                    className="btn btn-outline-light font-weight-light"
                  >
                    Смотреть
          </Link></div>

                </Card.ImgOverlay>
                <Card.Footer className="rgba-black-strong-imperia" style={{ height: '60px', }}><h2 className="m-0 text-center text-white font-weight-normal">Гардеробные</h2></Card.Footer>
              </Card>
            </Fade>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Fade duration={1000} delay={150}>
              <Card className="bg-light text-white rounded-0 border-0 m-3">
                <Img
                  fluid={props.data.photoOther.childImageSharp.fluid}
                />
                <Card.ImgOverlay className="rgba-black-strong-imperia" style={{ bottom: '60px', }}>

                  <Card.Text className="h5 font-weight-normal">Мебель в мастерскую, кладовую и т.п.</Card.Text>
                  <Card.Text className="h5 font-weight-normal">Производство на заказ</Card.Text>
                  <div className="btn-container mt-3"><a
                    href="#"
                    className="btn btn-outline-light font-weight-light disabled"
                  >
                    Скоро
          </a></div>

                </Card.ImgOverlay>
                <Card.Footer className="rgba-black-strong-imperia" style={{ height: '60px', }}><h2 className="m-0 text-center text-white font-weight-normal">Иная мебель</h2></Card.Footer>
              </Card>
            </Fade>
          </Col>
        </Row>
      </Container>
      <CatalogFullscreenSections />
      <FloatingContacts />
      {/* <div
        style={{
          position: "fixed",
          right: "0px",
          zIndex: "800",
          bottom: "0px",
        }}
        className="d-flex flex-column p-2 bg-white"
      >
        <a href="tel:+7-921-908-0881" className="mb-2">
          <FontAwesomeIcon
            icon={faPhone}
            style={{
              color: "#000000CC",
            }}
            className="fa-2x"
          />
        </a>
        <a href="https://wa.me/79219080881" className="mb-2">
          <FontAwesomeIcon
            icon={faWhatsapp}
            style={{
              color: "#000000CC",
            }}
            className="fa-2x"
          />
        </a>
        <a href="https://www.instagram.com/imperia_st.meb/" className="mb-2">
          <FontAwesomeIcon
            icon={faInstagram}
            style={{
              color: "#000000CC",
            }}
            className="fa-2x"
          />
        </a>
        <a href="https://vk.com/club95884987">
          <FontAwesomeIcon
            icon={faVk}
            style={{
              color: "#000000CC",
            }}
            className="fa-2x"
          />
        </a>
      </div> */}
      <CookiesAlert />
    </>
  )
}

export default CatalogPage

export const pageQuery =
  graphql`
    query {
      cupboard: file(relativePath: { eq: "cupboard_2.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hallway: file(
        relativePath: { eq: "hallway_2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kitchen: file(
        relativePath: { eq: "kitchen_2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dressingRoom: file(
        relativePath: { eq: "dressing-room_2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stand: file(
        relativePath: { eq: "stand_2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      photoOther: file(
        relativePath: { eq: "other-furniture_2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;