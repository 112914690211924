import React from "react"
import { navigate, Link } from "gatsby"
import "./catalog-fullscreen-sections.scss"

export default function catalogFeatures() {
  return (
    <>
      <div class="catalog-sections-container bg-dark">
        <div class="bg1 item" onClick={() => navigate(`/decor`)}>
          <h2 className="text-white px-3 font-weight-normal">Декор</h2>
          <Link
            to="/decor"
            className="d-block btn btn-outline-light font-weight-light"
          >
            Смотреть
          </Link>
        </div>
        <div class="bg2 item" onClick={() => navigate(`/materials`)}>
          <h2 className="text-white px-3 font-weight-normal">
            Материалы, которые мы используем в своей работе
          </h2>
          <p className="text-white px-3 font-weight-light">
            ЛДСП EGGER, ЛДСП LAMARTY, ЛДСП KRONOSPAN, EVOGLOSS, фасады из МДФ в
            эмали, изделия из шпона дерева, эко-плиты TSS CLEAF, HPL пластик,
            ACRYLINE, RAUVISIO BRILLIANT, стеклоламинат RAUVISIO CRYSTAL,
            акриловый пластик, FENIX, каменный шпон, массив дерева
          </p>
          <Link
            to="/materials"
            className="d-block btn btn-outline-light font-weight-light"
          >
            Смотреть
          </Link>
        </div>
        <div class="bg3 item" onClick={() => navigate(`/appliances-options`)}>
          <h2 className="text-white px-3 font-weight-normal">Варианты техники</h2>
          <Link
            to="/appliances-options"
            className="d-block btn btn-outline-light font-weight-light"
          >
            Смотреть
          </Link>
        </div>
      </div>
    </>
  )
}
